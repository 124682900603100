import axios from 'axios'
import JSONBig from 'json-bigint'

const jsonRequest = axios.create({
  // 接口的基准路径
  // baseURL: 'https://xheryuanapp.xh2ph.com:8443/zzj/wxapp',
  // baseURL: 'http://172.16.1.3:8082/zzj/wxapp',
  baseURL: 'http://192.168.1.232:8084/wxapp',
  transformResponse: [
    function(data) {
      try {
        return JSONBig.parse(data)
      } catch (err) {
        return data
      }
    }
  ]
})

export default jsonRequest
