import formRequest from './formRequest'
import jsonRequest from './jsonRequest'

//json post请求
export const jsonPost = function (url, data) {
    console.log(data);
    return jsonRequest({
        url: url,
        method: 'POST',
        data: data
    })
}

//表单 post请求
export const formPost = function (url, data) {
    return formRequest({
        url: url,
        method: 'POST',
        data: data
    })
}

